import React, {useEffect} from "react";
import {Box, Card, Grid, Typography} from "@material-ui/core";
import {HomeLocationContextProvider} from "../components/context/HomeLocationContext";
import {Institution, useGetInstitutionsByIdLazyQuery} from "../components/data/graphqlTypes";
import {
    getLastInstitutionId,
    getPathForLastVisitedInstitutionId,
    storeInstitutionIdInLocalStorage
} from "../utilities/localstorage/InstitutionContextStore";
import {useGetUserInstitutions} from "../components/context/UserInstitutionContext/useGetUserInstitutions";
import _ from "lodash";
import ApolloGqlQueryDisplay from "../components/data/ApolloGqlQueryDisplay";
import {Image} from "react-bootstrap";
import {makeStyles} from "@material-ui/core/styles";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import BackgroundImage from "../Images/CreditCardBackground.png";
import CreditCards from "../components/PaymentOptions/CreditCard/CreditCards";
import DirectDebitEditor from "../components/DirectDebit/DirectDebitEditor";
import useGetCustomer from "../components/context/EntityContext/useGetCustomer";
import AutoPayConfigurations from "../components/PaymentOptions/AutoPayConfigurations";
import {Link} from "react-router-dom";

const mockAccountPaymentCallback = (
    institutionCustomerBalanceId: string,
    autoPayAccountId: string
): Promise<void> => {
    return new Promise<void>((resolve) => {
        console.log(`Payment Callback Triggered for balance ID ${institutionCustomerBalanceId} and account ID ${autoPayAccountId}`);
        resolve();
    });
};

export const useSettingsStyles = makeStyles((theme) => ({
    cardContainer: {
        minWidth: 200,
        maxWidth: 600,
        maxHeight: "fit-content",
        marginTop: '5vh',
        marginRight: '5px',
        marginLeft: '5px',
        marginBottom: '5vh',
        border: '0px solid #4D3B71',
        borderRadius: 12,
        boxShadow: "0 6px 20px #4d3b71",
        [theme.breakpoints.up("md")]: {
            maxWidth: 800,
            minWidth: 800,
        },
        [theme.breakpoints.down("sm")]: {
            marginTop: '2vh',
        },
    },
    creditCardCard: {
        minWidth: '125px',
        maxWidth: "600px",
        height: 'auto',
        paddingTop: '5px',
        paddingBottom: '5px',
        maxHeight: "400px",
        marginTop: '2vh',
        marginRight: '60px',
        marginLeft: '60px',
        marginBottom: '3vh',
        borderColor: 'transparent',
        borderRadius: 6,
        [theme.breakpoints.down("sm")]: {
            marginLeft: "0px",
            marginRight: "0px",
        },
    },
    stepperButton: {
        outlineColor: 'transparent'
    },
    imageLogo: {
        height: "100%",
        width: "100%",
        borderColor: 'transparent'
    },
    imageLogo2: {
        minWidth: '100%',
        borderColor: 'transparent'
    },
    titleTypography: {
        marginBottom: "20px",
        marginLeft: "65px",
        marginRight: "65px",
        [theme.breakpoints.down("sm")]: {
            marginLeft: "5px",
            marginRight: "5px",
        },
    },
    titleTypography2: {
        marginBottom: "20px",
        marginLeft: "55px",
        [theme.breakpoints.down("sm")]: {
            marginLeft: "0px",
        },
    },
    tabs: {
        '& .MuiTabs-indicator': {
            display: 'none',
        },
        "& .MuiButtonBase-root.MuiTab-root": {
            fontSize: 20,
            textTransform: "none",
            [theme.breakpoints.down("xs")]: {
                fontSize: 14,
                paddingRight: 5,
                paddingLeft: 5,
            },
        },
    },
    tabPanel: {
        padding: '48px',
        [theme.breakpoints.down("xs")]: {
            padding: '35px 5px 15px 5px',
        },
    },
    cardRoot: {
        minWidth: 275,
        maxHeight: 150,
        borderColor: "#f4f4f4",
        backgroundImage: `url(${BackgroundImage})`
    },
    cardBrand: {
        width: "100%",
        padding: 5,

    },
    topMargin: {
        marginTop: "5vh",
    }
}));


interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: any;
    value: any;
}

export function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;
    const classes = useSettingsStyles();

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3} className={classes.tabPanel}>
                    {children}
                </Box>
            )}
        </div>
    );
}


function getFirstInstitutionWithCardEnabled(institutions: Array<Institution | undefined | null>): Institution | undefined | null {
    if (!institutions) {
        return undefined;
    }
    return institutions.find(institution => institution && institution.creditCardEnabled);
}

function isCreditCardsEnabled(institutions: Array<Institution | null> | undefined): boolean {
    if (!institutions) {
        return false;
    }

    return !!getFirstInstitutionWithCardEnabled(institutions);
}

const createUserProfileFirst = (
    <Typography>
        Please complete your user profile before adding a credit card or debit card.
        <br/><Link to="/userProfile"> Go to User Profile</Link>
    </Typography>
);

const PaymentSettings = () => {
    const classes = useSettingsStyles();
    const [visibleTab, setVisibleTab] = React.useState(0);
    const {customer} = useGetCustomer();
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setVisibleTab(newValue);
    };
    let lastInstitutionId = getLastInstitutionId();

    const {
        userInstitutions,
        error: userInstitutionError
    } = useGetUserInstitutions({institutionId: lastInstitutionId});

    const [
        getInstitutionsById,
        {
            data: institutionsData,
            error: institutionsError
        }] = useGetInstitutionsByIdLazyQuery();

    useEffect(() => {
        let institutionIds = [lastInstitutionId];
        if (userInstitutions && userInstitutions.length > 0) {
            const userInstitutionIds = userInstitutions.map(userInstitution => userInstitution.id);
            institutionIds = _.sortedUniq(_.concat(userInstitutionIds, institutionIds));
        }
        getInstitutionsById({variables: {institutionIds: institutionIds.filter(value => value !== null) as Array<string>}});
    }, [userInstitutions, lastInstitutionId]);

    useEffect(() => {
        if (!!lastInstitutionId) {
            return;
        }
        if (institutionsData?.institutions && institutionsData?.institutions.length > 0) {
            const firstId = institutionsData.institutions[0]?.institutionId;
            if (!!firstId) {
                storeInstitutionIdInLocalStorage(firstId);
                lastInstitutionId = getPathForLastVisitedInstitutionId();
            }
        } else if (userInstitutions?.length && userInstitutions?.length > 0) {
            const firstId = userInstitutions[0].id;
            storeInstitutionIdInLocalStorage(firstId);
            lastInstitutionId = getPathForLastVisitedInstitutionId();
        }

    }, [lastInstitutionId, institutionsData, userInstitutions]);


    const institutions = institutionsData?.institutions;
    if (!institutions || institutions.length < 1) {
        return <ApolloGqlQueryDisplay
            loadingMessage={"Loading Institutions"}
            error={userInstitutionError || institutionsError}
            loading={true}
        />;
    }
    const lastInstitution = institutions.find(institution => institution && institution.institutionId === lastInstitutionId);


    return (
        <>
            {lastInstitution &&
                <HomeLocationContextProvider
                    institution={lastInstitution}
                >
                    <Grid container direction='column' justifyContent="center" alignItems="center">
                        <Grid item xs={12}>
                            <Card variant={"elevation"} className={classes.cardContainer}>
                                <Tabs
                                    className={classes.tabs}
                                    value={visibleTab}
                                    onChange={handleChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    centered
                                >

                                    <Tab label="Payment Accounts"/>
                                    {/*<Tab label="Auto Pay"/>*/}
                                </Tabs>
                                <TabPanel index={0} value={visibleTab}>
                                    <Grid container direction="row" justifyContent="center"
                                          alignItems="center">
                                        {!!customer?.firstName ? (
                                                <>
                                                    {isCreditCardsEnabled(institutions) &&
                                                        <>
                                                            <Grid item xs={12} className={classes.titleTypography}>
                                                                <Typography variant={"h2"}>Credit
                                                                    Cards</Typography>
                                                            </Grid>
                                                            <Grid container direction={"column"}
                                                                  justifyContent={"space-evenly"}>
                                                                {institutions && institutions.length > 0 &&
                                                                    institutions
                                                                        .map(institutionMaybe => institutionMaybe as Institution)
                                                                        .filter(institution => institution.creditCardEnabled)
                                                                        .map(institution => (
                                                                            <Card className={classes.creditCardCard}
                                                                                  key={institution.institutionId}>
                                                                                <Grid container direction="row"
                                                                                      justifyContent="space-between">
                                                                                    <Grid item xs={4} sm={2}>
                                                                                        <Image
                                                                                            className={classes.imageLogo2}
                                                                                            src={institution.logoUrl}
                                                                                            alt={institution.name}
                                                                                            thumbnail/>
                                                                                    </Grid>
                                                                                    <Grid item xs={8}>
                                                                                        <CreditCards
                                                                                            institutionId={
                                                                                                institution?.institutionId ||
                                                                                                lastInstitution.institutionId
                                                                                            }
                                                                                        />
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Card>
                                                                        ))
                                                                }
                                                            </Grid>
                                                        </>
                                                    }
                                                    <Grid container direction={"column"}
                                                          justifyContent={"space-evenly"}>
                                                        <Grid item xs={12}>
                                                            <Typography className={classes.titleTypography}
                                                                        variant={"h2"}>Direct
                                                                Debit Accounts</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <DirectDebitEditor/>
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            ) :
                                            createUserProfileFirst
                                        }
                                    </Grid>
                                </TabPanel>
                                {/*<TabPanel index={1} value={visibleTab}>*/}
                                {/*    <Grid container direction="row" justifyContent="center"*/}
                                {/*          alignItems="center">*/}
                                {/*        {!!customer?.firstName ? (*/}
                                {/*                <AutoPayConfigurations/>*/}
                                {/*            ) :*/}
                                {/*            createUserProfileFirst*/}
                                {/*        }*/}
                                {/*    </Grid>*/}
                                {/*</TabPanel>*/}
                            </Card>
                        </Grid>
                    </Grid>
                </HomeLocationContextProvider>
            }
        </>

    );
};

export default PaymentSettings;
