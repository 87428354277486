import {gql} from "@apollo/client";

export const getCustomerProfile = gql`
    query getCustomerProfile {
        customer : getCustomerProfile {
            countryCode
            mobile
            firstName
            lastName
            regionCode
            address
            postalCode
            city
            isVerified
        }
    }
`;


gql`
    mutation createCustomerProfile($customerProfileInput: CustomerProfileInput!) {
        profile: createCustomerProfile(customerProfileInput: $customerProfileInput) {
            firstName
            lastName
            countryCode
            regionCode
            mobile
            address
            postalCode
            city
        }
    }
`;

gql`
    mutation updateCustomerProfile($customerProfileInput: CustomerProfileInput!) {
        profile: updateCustomerProfile(customerProfileInput: $customerProfileInput) {
            firstName
            lastName
            countryCode
            regionCode
            mobile
            address
            postalCode
            city
            isVerified
        }
    }
`;

gql`
    mutation updateUserInstitution($userInstitutionInput: CustomerInstitutionInput!) {
        updateCustomerInstitution(customerInstitutionInput: $userInstitutionInput) {
            id
            units
        }
    }
`;
