import {Nav, NavDropdown} from "react-bootstrap";
import React from "react";
import {useHistory} from "react-router-dom";
import {Customer} from "../context/EntityContext/useGetCustomer";
import useAuthorization from "../auth/useAuthorization";


function nameInitials(customer: Customer) {

    return customer.firstName ? `${customer.firstName.charAt(0)}${customer.lastName.charAt(0)}` : ":)";
}

type PropsType = {
    entity?: Customer
}


const AuthenticatedMenu = (props: PropsType) => {

    const history = useHistory();
    const {logout} = useAuthorization();
    const initials = !!props.entity ? nameInitials(props.entity) : "NU";
    return <Nav>
        <NavDropdown aria-expanded={"true"} id="nav-link " title={initials}>

            {/*<NavDropdown.Item*/}
            {/*    onClick={() => history.push("/balanceHistory")}*/}
            {/*    eventKey="Invoices"*/}
            {/*>Account Balance</NavDropdown.Item>*/}
            {/*<NavDropdown.Divider/>*/}

            <NavDropdown.Item
                eventKey="Make a Payment"
                onClick={() => history.push("/payment")}
            >Make Payment
            </NavDropdown.Item>
            <NavDropdown.Divider/>

            <NavDropdown.Item
                eventKey="Payment Settings"
                onClick={() => history.push("/paymentSettings")}
            >Payment Settings
            </NavDropdown.Item>
            <NavDropdown.Divider/>

            <NavDropdown.Item
                eventKey="User Profile"
                onClick={() => history.push("/userProfile")}
            >User Profile
            </NavDropdown.Item>
            <NavDropdown.Divider/>

            <NavDropdown.Item
                onClick={() => history.push("/history")}
                eventKey="Transactions"
            >Transaction History</NavDropdown.Item>
            <NavDropdown.Divider/>



            <NavDropdown.Item
                eventKey="logout"
                onClick={() => logout()}
            >Sign Out
            </NavDropdown.Item>

        </NavDropdown>
    </Nav>
}

export default AuthenticatedMenu;